require('../../node_modules/uikit/dist/js/uikit.min.js')
//import UIkit from 'uikit'

//UIkit.slider('.uk-slider', {autoplay:true});
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }
} );


jQuery(document).ready(function($){
    $('.product-detail-main a:not(.order-btn)').attr("target", "_blank");
});
